<template>
  <div
    class="container"
    style="margin-top: 48px; margin-bottom: 48px;">
    <div class="DetailsPage__Content">
      <div
        class="ContactVdBlock"
        style="margin-left: auto;margin-right: auto;">
        <div class="col-md-12">
          <h1 class="title_faq">
            Terms and Conditions
          </h1>

          <ul class="nav nav-tabs faq">
            <li class="active faq_li">
              <a
                data-toggle="tab"
                href="#rent"
                class="faq_link">Rent-a-car</a>
            </li>
          </ul>

          <div class="tab-content faq_tabs">
            <div
              id="rent"
              class="tab-pane active">
              <h3 class="headinng_sec_2">
                Terms and Conditions for Fleet Management Rental Agreement
              </h3>
              <ol
                class="terms_ol"
                style="margin-top:40px">
                <li>
                  <span>Interpretation and Definitions</span>
                  <ol>
                    <li>
                      In this Agreement, unless inconsistent with the context:
                      <ol>
                        <li>
                          <span>“Account”</span> means the account opened in our books in respect of this Agreement;
                        </li>
                        <li>
                          <span>“Agent”</span> means a third party appointed by the Bank to facilitate the physical inspection of and delivery of the Vehicle to the Customer;
                        </li>
                        <li>
                          <span>“Agreement”</span> means the rental schedule <span>(Part A)</span> of this agreement, attached to and read together with these terms and conditions <span>(Part B)</span> and all letters and notices relating to same;
                        </li>
                        <li>
                          <span>“Applicable Laws”</span> means all national, provincial, local and municipal legislation or subordinate legislation, ordinances, regulations, by-laws, rules and/or other laws of any relevant regulatory authority and any other instrument having the force of law as may be issued and in force from time to time as well as common law, all as relating to or connected with the activities contemplated under this Agreement and as updated from time to time;
                        </li>
                        <li>
                          <span>“Bank”</span> means The Standard Bank of South Africa Limited (Registration number 1962/000738/06) a public company duly incorporated with limited liability according to the company laws of the Republic of South Africa and/or its successors in title or assigns;
                        </li>
                        <li>
                          <span>“Business Day(s)”</span> mean any day(s) other than a Saturday, Sunday or statutory holiday in the Republic of South Africa;
                        </li>
                        <li>
                          <span>“Collateral”</span> means any security provided to us to secure the repayment of the monthly rentals;
                        </li>
                        <li>
                          <span>“Collateral Provider”</span> means each person and/or entity who is to provide Collateral to the Bank in respect of the due performance by you of your payment and other obligations in terms of this Agreement and Collateral Providers means all of them as the context may indicate;
                        </li>
                        <li>
                          <span>“CPA”</span> means the Consumer Protection Act 68 of 2008 and all regulations promulgated in terms of this act, as amended from time to time;
                        </li>
                        <li>
                          <span>“Customer Rental Portal”</span> means the online website utilised by the Bank for the display of the Vehicles;
                        </li>
                        <li>
                          <span>“Fair Wear and Tear Guide”</span> means the guide attached to this Agreement as Annexure B;
                        </li>
                        <li>
                          <span>“FICA”</span> means the Financial Intelligence Centre Act 38 of 2001 and all regulations promulgated in terms of this act, as amended from time to time;
                        </li>
                        <li>
                          <span>“Natural Person”</span> means a private individual for purposes of this definition;
                        </li>
                        <li>
                          <span>“Parties”</span> means the Bank and the Customer and Party means either one of them;
                        </li>
                        <li>
                          <span>“Personal Information”</span> means information about an identifiable, natural person and where applicable, a juristic person, including, but not limited to information about: race; gender; sex; pregnancy; marital status; nationality; ethnic or social origin; colour; sexual orientation; age; physical or mental health; well-being; disability; religion; conscience; belief; culture; language; birth; education; medical, financial, criminal or employment history; any identifying number, symbol,  e-mail, postal or physical address, telephone number; location;  any online identifier; any other particular assignment to the person; biometric information; personal opinions, views or preferences of the person or the views or opinions of another individual about the person; correspondence sent by the person that is implicitly  or explicitly of a private or confidential nature or further correspondence that would reveal the contents of the original correspondence; and the name of the person if it appears with other personal information relating to the person or if the disclosure of the name itself would reveal information about the person.
                        </li>
                        <li>
                          <span>“Prime Rate”</span> means the publicly quoted overdraft rate of interest of the Bank from time to time as certified by any manager of the Bank, whose authority need not be proved and such certification will be binding in the absence of an obvious error;
                        </li>
                        <li>
                          <span>“Private Use”</span> means the use of the vehicle in any way other than for gain or remuneration;
                        </li>
                        <li>
                          <span>“Process”</span> means any operation or activity, automated or not, concerning Personal Information, including: alteration, blocking, collation, collection, consultation, degradation, destruction, dissemination by means of transmission, distribution or making available in any other form, erasure, linking, merging, organisation, receipt, recording, retrieval, storage, updating, modification, or the use of information. <span>Processing</span> and <span>Processed</span> will have a similar meaning;
                        </li>
                        <li>
                          <span>“Prohibited Activities”</span> means illegal or terrorist activities, money laundering or any activities which are subject to Sanctions or are not in compliance with Applicable Laws;
                        </li>
                        <li>
                          <span>“Sanctions”</span> means any restrictions set by a Sanctioning Body, including but not limited to diplomatic, travel, trade or financial sanctions or embargoes;
                        </li>
                        <li>
                          <span>Sanctioning Body</span> means the United Nations Security Council <span>(UNSC)</span>, the Office of Foreign Assets Control of the Department of Treasury of the United States of America <span>(OFAC)</span>, the European Union <span>(EU)</span>, Her Majesty’s Treasury <span>(HMT)</span>, the Ministry of Economy, Finance and Industry (France) <span>(MINEFI)</span> or any other sanctioning body we recognise from time to time
                        </li>
                        <li>
                          <span>“Sanctioned Entity”</span> means:
                          <ol>
                            <li>
                              any natural or juristic person or country;
                            </li>
                            <li>
                              in the case of a juristic person, any person who (i) owns or controls it; or (ii) it owns or controls (and for these purposes, owns means holding any percentage of ownership or beneficial interest and controls means the ability, directly or indirectly and whether through the voting of shares, the appointment of directors or similar officers or through any other means, to control the business or policy of the juristic person);
                            </li>
                            <li>
                              in the case of any country, its ministries, departments, agencies or any other governmental organisations, listed on any Sanctions List or who is subject to any Sanctions;
                            </li>
                          </ol>
                        </li>
                        <li>
                          <span>“Sanctions List”</span> means any list of Sanctioned Entities published by a Sanctioning Body, as updated from time to time;
                        </li>
                        <li>
                          <span>“Standard Bank Group”</span> means Standard Bank Group Limited and its subsidiaries and their subsidiaries;
                        </li>
                        <li>
                          <span>“Statement”</span> means the document reflecting the transactions made on your Account up to the date of the statement; the full amount due to us (including any legally permissible interest, costs, fees and charges that may be levied); the rental; and the payment due date;
                        </li>
                        <li>
                          <span>“VAT”</span> means the value added tax charged in terms of the VAT Act;
                        </li>
                        <li>
                          <span>“VAT Act”</span> means the Value Added Tax Act 89 of 1991 together with all regulations promulgated in terms of this act, as amended from time to time;
                        </li>
                        <li>
                          <span>“Vehicle”</span> means the vehicle more fully described in Part A of this Agreement;
                        </li>
                        <li>
                          <span>“we”, “us”</span> or <span>“our”</span> means the Bank and its transferees;
                        </li>
                        <li>
                          <span>“you”</span> or <span>“your”</span> means the Customer;
                        </li>
                        <li>
                          Any reference to:
                          <ol>
                            <li>
                              the singular includes the plural and vice versa;
                            </li>
                            <li>
                              one gender includes the other genders;
                            </li>
                            <li>
                              Natural Persons includes juristic persons and vice versa; “person” includes a firm, body corporate, unincorporated association or any other body or entity;
                            </li>
                          </ol>
                        </li>
                        <li>
                          The term “including” means “including, but not limited to” and “includes” shall bear a corresponding meaning.
                        </li>
                      </ol>
                    </li>
                    <li>Headings shall not be taken into account in the interpretation hereof.</li>
                    <li>The expiry or termination of this Agreement will not affect those terms that expressly state that they will operate after expiry or termination, or those that of necessity must continue to have effect after expiry or termination, even if the clauses themselves do not expressly state this.</li>
                  </ol>
                </li>
                <li>
                  <span>RENTAL</span><br>We hereby rent the Vehicle to you and you hire the Vehicle from us on the terms and conditions set out in this Agreement.
                </li>
                <li>
                  <span>OWNERSHIP AND RISK</span>
                  <ol>
                    <li>
                      We shall at all times during and after termination of this Agreement remain the owner of the Vehicle.
                    </li>
                    <li>
                      Subject to the CPA, the risk in and to the Vehicle, including but not limited to liability for loss and damage to and arising from the Vehicle and/or the use, possession or operation of the Vehicle, will pass to you on the date on which you sign the authority to release the Vehicle and this Agreement and shall remain with you until the Vehicle is returned to us.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>DELIVERY</span>
                  <ol>
                    <li>
                      You confirm that you have identified and selected the Vehicle from the Customer Rental Portal.
                    </li>
                    <li>
                      You further confirm that you have been allowed the opportunity to physically inspect the Vehicle at our selected Agent to satisfy yourself with the condition of the Vehicle.
                    </li>
                    <li>
                      You must at your cost obtain and take delivery of the Vehicle from the selected Agent at the agreed place of delivery.
                    </li>
                    <li>
                      Delivery or tender of delivery of the Vehicle within 30 days of the commencement date will be regarded as delivery of the Vehicle by us to you.
                    </li>
                    <li>
                      By signing the Agreement, you agree to the parameters relating to the term/period of the Agreement as well as the kilometre use as specified in Part A.
                    </li>
                    <li>
                      Before signing the authority to release the Vehicle, you must:
                      <ol>
                        <li>
                          conduct a comprehensive pre-delivery inspection of the Vehicle to reasonably confirm that the Vehicle is in good order and condition as per the condition report on the Customer Rental Portal and that there are no reasonably detectable defects in the Vehicle before taking delivery of the Vehicle; and
                        </li>
                        <li>
                          accept the Vehicle, by signing the authority to release the Vehicle; and
                        </li>
                        <li>
                          subject to the CPA, by signing the authority to release the Vehicle and the Agreement you agree that no warranties or representations have been made or given by us as to the state, condition or fitness of the Vehicle; and
                        </li>
                        <li>
                          ensure that the odometer, tachograph or other distance/usage measuring device fitted to the Vehicle (“the meter”) is sealed.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <span>PAYMENT</span>
                  <ol>
                    <li>
                      You must pay the rentals on the due dates set out in Part A to this Agreement and at such address as we nominate or in terms of clause 5.4 below. To the maximum extent permitted in law, all payments in terms of this Agreement must be made without deductions of any kind, and must be free of exchange, bank costs or other charges.
                    </li>
                    <li>
                      Your payment obligations are unconditional and, to the extent permitted in law, are not subject to set-off or reduction for any reason. You may also not delay or withhold payments due to us on the basis of any claim which you may have against us. If you fail to pay the rentals on the due date, any outstanding amount will attract interest as specified in clause 9.7 below.
                    </li>
                    <li>
                      If we cancel this Agreement and you dispute the cancellation, you must continue to pay all amounts on the due date while you remain in possession of the Vehicle. Acceptance of your payments will not in any way affect any rights we may have against you. If the dispute is decided or settled in our favour, we may keep any amounts paid by you after cancellation of the Agreement as compensation for the losses we have incurred because you did not return the Vehicle on cancellation.
                    </li>
                    <li>
                      You will make payments by debit order. You authorise and instruct us to draw against your bank account, wherever it may be, all payments and amounts due in terms of this Agreement.
                    </li>
                    <li>
                      If the rental is linked to the Prime Rate, we may adjust the rentals payable by you on or after the date of a change in the Prime Rate or if our costs increase due to a change in or the introduction of any law or regulation or a change in the interpretation thereof. We may also adjust the rentals due to changes in VAT rates from time to time.
                    </li>
                    <li>
                      We may at any time apply any amount paid by you in terms hereof to pay any other debt you may have with us. You waive the right to name the debt to which any payment will be applied.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>USE OF THE VEHICLE</span>
                  <ol>
                    <li>
                      You must:
                      <ol>
                        <li>
                          ensure that the terms and conditions of any insurance policy in respect of the Vehicle are not contravened.
                        </li>
                        <li>
                          ensure that the Vehicle is not used for any unlawful purpose and, unless otherwise agreed to in writing, that the Vehicle is for “on-road” standard use only.
                        </li>
                        <li>
                          ensure that the Vehicle is only used with due skill and care and in accordance with the manufacturer’s specifications.
                        </li>
                        <li>
                          the Vehicle may not be used to transport persons or property for reward or for driving tuition or hire without our prior written consent.
                        </li>
                        <li>
                          at your own cost, ensure compliance with any laws in respect of the hire, possession, operation, licensing, registration, insurance and use of the Vehicle.
                        </li>
                        <li>
                          not allow the Vehicle to be driven by anyone not holding a valid driver’s licence for that category of motor vehicle or where the driver’s licence has been endorsed for any reason or driving while under the influence of intoxicating liquor or narcotic substances.
                        </li>
                        <li>
                          not permit any unauthorised person to drive the Vehicle during the term of this Agreement.
                        </li>
                        <li>
                          not convey any materials or articles in the Vehicle that may cause damage to its upholstery or to any other part of the Vehicle.
                        </li>
                        <li>
                          not use or permit the Vehicle to be used for any commercial enterprise or activities.
                        </li>
                        <li>
                          not drive or allow the Vehicle to be driven recklessly and/or negligently or in contravention of any road or traffics regulations or laws.
                        </li>
                        <li>
                          immediately inform us if the meter is faulty and repair or replace the meter without delay and you may not unseal or tamper with the meter in any way.
                        </li>
                        <li>
                          if the Vehicle is kept on premises that you do not own, immediately notify us in writing of the name and address of the owner of the premises and you must also notify the landlord in writing that we own the Vehicle.
                        </li>
                        <li>
                          not alter the Vehicle and you must return the Vehicle to us in its original condition.
                        </li>
                        <li>
                          not remove the Vehicle from the Republic of South Africa without our prior written consent, which, if given, may be subject to certain conditions and you hereby indemnify us against any direct or indirect loss or liability that we may incur as a result of your removal of the Vehicle without our prior consent.
                        </li>
                        <li>
                          not sell, transfer, let go of or give anyone else rights in the Vehicle, nor allow them to become subject to any legal burden and if the Vehicle becomes subject to legal burden, you will immediately procure its release from the same.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <span>VEHICLE MAINTENANCE AND SERVICING</span>
                  <ol>
                    <li>
                      The remainder of the manufacturer’s maintenance or service plan as indicated in Part A to this Agreement will be assigned to you. All items not included in the maintenance or service plan will be for your own account.
                    </li>
                    <li>
                      Where the maintenance or service plan on the Vehicle has lapsed, you must service and maintain the Vehicle at your own cost, strictly in accordance with the manufacturer’s recommendations and only at enfranchised dealers or the manufacturer.
                    </li>
                    <li>
                      You must:
                      <ol>
                        <li>
                          exercise the same degree of care necessary to maintain the Vehicle in a good and roadworthy condition as would ordinarily be exercised if the Vehicle belonged to you;
                        </li>
                        <li>
                          abide by the manufacturer’s specifications in regard to the Vehicle and not change or alter any aspect of the Vehicle without the Bank’s prior written consent to do so;
                        </li>
                      </ol>
                    </li>
                    <li>
                      All parts or accessories that are replaced on or added to the Vehicle will become our property without compensation to you.
                    </li>
                    <li>
                      The Vehicle will be installed with a tracking device so that the Bank can monitor the location and kilometres and other usage of the Vehicle and monitor whether the Vehicle is maintained in accordance with the terms and conditions of this Agreement.
                    </li>
                    <li>
                      Where you fail to submit the Vehicle for its recommended service timeously and the maintenance or service plan is terminated, you will be responsible for and must pay to the Bank, on demand, any costs associated with reinstatement of the maintenance or service plan and any costs arising from the Bank’s having to service the Vehicle.
                    </li>
                    <li>
                      The Bank will conduct an annual inspection of the Vehicle on or about each anniversary date of this Agreement. You must download a photograph of the Vehicle’s license disc, odometer reading and left and right-side front and rear bumpers of the Vehicle to the electronic link that the Bank will send to you at your chosen e-mail address.
                    </li>
                    <li>
                      Notwithstanding clause 7.7 above, we may conduct a physical inspection of the Vehicle at any reasonable time, wherever it is kept.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>INSURANCE</span>
                  <ol>
                    <li>
                      The Vehicle is insured with Standard Bank Insurance Limited and the Bank’s interest must be noted on the insurance policy.  The monthly insurance premium due is included in the rental payable by you.
                    </li>
                    <li>
                      You confirm that a copy of the insurance policy was provided to you on signature of this Agreement.
                    </li>
                    <li>
                      You must comply with all the terms and conditions of the insurance policy.
                    </li>
                    <li>
                      Subject to your rights under the CPA, if the Vehicle is:
                      <ol>
                        <li>
                          damaged, destroyed or lost, you must immediately notify us in writing and if required by us you must do everything necessary to ensure payment to us of the proceeds under the insurance policy; and/or
                        </li>
                        <li>
                          damaged and if required by us, you must restore the Vehicle at your own cost according to the manufacturer’s specifications and continue to meet all your obligations on or before their due date; and/or
                        </li>
                        <li>
                          damaged beyond economic repair, you must continue to meet your obligations to us under this Agreement or we will be entitled to cancel this Agreement by following the provisions of clause 9.4 of this Agreement; and/or
                        </li>
                        <li>
                          lost or destroyed and, where applicable, not recovered by us for any reason whatsoever, the value of the Vehicle will be deemed to be nil and we will be entitled to claim from you all outstanding amounts under this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We will not be required to provide you with a substitute Vehicle or to credit your Account for lack of use of the Vehicle whether under the circumstances referred to in clause 8.4 or on any other occasion when the Vehicle is not available for your use.
                    </li>
                    <li>
                      Should any claim be made or any proceedings be instituted against us, you or any other person as a direct or indirect consequence of the use, possession, maintenance or operation of the Vehicle or as a result of an insurable event, you will notify us of such proceeding or event immediately.
                    </li>
                    <li>
                      You will cooperate fully with us and render all assistance in the investigation and defence of any claim or proceedings arising from a situation referred to in clause 8.6.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>DEFAULT</span>
                  <ol>
                    <li>
                      Default in terms of this Agreement will occur:
                      <ol>
                        <li>
                          if you fail to keep the Vehicle licensed and registered with the relevant authorities;
                        </li>
                        <li>
                          if you fail to pay any amount payable to us under this Agreement on the due date;
                        </li>
                        <li>
                          if there is a material deterioration in your financial position. (For purposes of this clause, “material deterioration” means material deterioration in our reasonable opinion.)
                        </li>
                        <li>
                          if you fail to comply with any legislation or regulations applicable to this Agreement and your activities including but not limited to any environmental laws or responsibilities and, where applicable, any company legislation;
                        </li>
                        <li>
                          if you breach any of the terms and conditions of this Agreement or any agreement in terms of which you provided Collateral to us, and you fail to remedy the breach within the timeframe provided for in the written notice to do so;
                        </li>
                        <li>
                          if any representation or warranty made in connection with this Agreement or any other document supplied by you is materially incorrect or false;
                        </li>
                        <li>
                          should you or any Collateral Provider (being a Natural Person):
                          <ol>
                            <li>
                              publish notice of the voluntary surrender of your/their estate or die;
                            </li>
                            <li>
                              be placed under administration or commit an act of insolvency as defined in the Insolvency Act 24 of 1936;
                            </li>
                            <li>
                              have any application or other proceedings brought against or in respect of you/them in terms of which you/they are sought to be sequestrated or placed under curatorship, whether provisionally or finally, whether voluntarily or under compulsion;
                            </li>
                          </ol>
                        </li>
                        <li>
                          if you generally do or omit to do anything that may affect our rights or Collateral in terms of this Agreement; or
                        </li>
                        <li>
                          if judgment of a competent court against you or any Collateral Provider for the attachment of assets or for payment of any amount remains unsatisfied for more than 7 (seven) days after the date on which it is issued.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If any of the above events happen or if the Vehicle is lost, destroyed or damaged beyond economic repair, we may, without prejudice to our other rights, after due demand:
                      <ol>
                        <li>
                          claim from you immediate payment of all amounts payable under this Agreement, whether due for payment or not, and if you do not make immediate payment, we may claim in terms of clause 9.2.2, whether or not we choose to claim payment in terms of this subclause; or
                        </li>
                        <li>
                          cancel this Agreement, take the Vehicle back and recover from you all arrear rentals. We may also recover, as pre-estimated liquidated damages,
                          <ol>
                            <li>
                              the sum of all amounts payable over the unexpired period of this Agreement as well as all the quoted costs to repair any damage to the Vehicle, fair wear and tear excepted, and any excess usage charges in respect of the Vehicle;
                            </li>
                            <li>
                              less the value of the Vehicle as at the date on which we recover the Vehicle or the proceeds of any insurance policy paid to us in respect of the Vehicle, net of all costs of transportation, storage, valuation and improvements to the Vehicle. If we are unable to recover the Vehicle, the value will be deemed to be nil.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      For all purposes of clause 9.2, “due demand” means written notice to you calling upon you to remedy an act of default or breach within a specified time period.
                    </li>
                    <li>
                      We will be allowed to choose a sworn appraiser to value the Vehicle and you will accept this valuation. If we recover the Vehicle, we may dispose of it in any way.
                    </li>
                    <li>
                      You will pay to us arrear interest at 3% (three per cent) above the Prime Rate on all overdue amounts. Interest will accrue from the date on which we acquire the right to claim from you until the date on which we receive payment from you.
                    </li>
                    <li>
                      If you fail to fulfil any obligation under this Agreement, we may (but do not have to), without prejudice to any of our other rights, fulfil the obligation on your behalf and you must reimburse us for our cost of doing so on demand.
                    </li>
                    <li>
                      If we have to pay you any amount in connection with this Agreement, that amount will not be due by us until you have paid all amounts you owe to us. We may set off any amount that we owe you against any amount you owe us under this Agreement.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>EARLY TERMINATION</span><br>
                  You may terminate this Agreement before the expiry date with our prior written consent, which we will not unreasonably refuse. If we consent to the early termination, you must immediately return the Vehicle at your own cost and pay to us all arrear rentals, arrear interest, the excess usage charges in terms of clause 9.2 if applicable, the quoted costs of repair of the Vehicle (fair wear and tear excepted) and an early termination fee quoted by us on the basis of, among other things, clause 9.2.2.1.
                </li>
                <li>
                  <span>YOUR OBLIGATIONS ON TERMINATION OF THIS AGREEMENT</span>
                  <ol>
                    <li>
                      On termination of this Agreement for any reason you must at your own cost return the Vehicle to us together with the keys, accessories, instruction books (if any), the service and warranty books, and the spare wheel.
                    </li>
                    <li>
                      On return of the Vehicle:
                      <ol>
                        <li>
                          we or our Agent will inspect the Vehicle according to the Fair Wear and Tear Guide attached hereto as Annexure B to determine any damages to the Vehicle and will complete an appraisal report. You or your agent may attend the inspection.
                        </li>
                        <li>
                          you or your agent must sign the appraisal report and endorse on the report in which respects you disagree with it. We will give you a copy of the report.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The report will be deemed to be correct:
                      <ol>
                        <li>
                          if you or your agent do not attend the inspection, and/or
                        </li>
                        <li>
                          with the exception of your or your agent’s endorsements on the report and latent defects.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If the Bank is required to make any repairs to the Vehicle, fair wear and tear excepted, or replace any accessories, keys or the spare wheel, these costs will be for your account.
                    </li>
                    <li>
                      Any additions and/or alterations made to the Vehicle while it is in your possession will become the property of the Bank.
                    </li>
                    <li>
                      You warrant that the Vehicle returned to us will be in the condition specified in the Fair Wear and Tear Guide.
                    </li>
                    <li>
                      If you dispute the condition of the Vehicle, the Vehicle will be examined by the manufacturer or, at our option, by an independent appraiser chosen by us (or his nominee) (“the expert”). The expert will act as an expert and not as an arbitrator and his decision will be final and binding on the Parties. The costs of the expert will be shared equally between the Parties.
                    </li>
                    <li>
                      If you do not return the Vehicle within 24 (twenty four) hours of the termination of this Agreement we may, without prejudice to our other rights, claim payment of an amount equal to the basic rental of the Vehicle for each month or part thereof that you have possession of the Vehicle after termination date.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>EXCESS USAGE</span>
                  <ol>
                    <li>
                      You agree that you have decided on the maximum monthly kilometres and estimated monthly usage of the Vehicle as stated in Part A of this Agreement,
                    </li>
                    <li>
                      You undertake that the average monthly usage of the Vehicle during any period under review will not exceed the average estimated monthly usage during that period by more than 10% (ten per cent). The Bank will be entitled to charge you an excess-kilometre fee as indicated in Part A to this Agreement for each kilometre in excess of the maximum monthly kilometres allowed. You will be notified in writing within 10 (ten) days from the end of each month of the excess-kilometre fee due and payable by you for the previous month. The Bank will issue a tax invoice to you for the payment of the excess-kilometre fees.
                    </li>
                    <li>
                      Notwithstanding anything to the contrary contained in this Agreement, if you exceed the agreed estimated maximum monthly kilometres by more than 10% (ten percent) in any three successive month period of the term of the Agreement, then the Bank has the right to restructure the Agreement, which may result in an increase of the monthly rental in Part A and/or an amendment of the rental period. The Bank may also exercise the right to cancel this Agreement in accordance with clause 9 above should the Bank at its sole discretion determine it necessary to do so.
                    </li>
                    <li>
                      The tracking device installed in the Vehicle must be used to determine the distance travelled by the Vehicle during the entire rental period. The information provided by the tracking device will be conclusive proof of the distance travelled and will be binding on both parties, except in the event of manifest error or tampering with the tracking device, in which case the Vehicle odometer reading will be relied on.
                    </li>
                    <li>
                      The tracking device will also monitor the speeds you travelled during the entire rental period. The information furnished by the tracking device will be conclusive proof of the speeds travelled and will be binding on the parties, except in the event of manifest error.
                    </li>
                  </ol>
                </li>
                <li>
                  <span id="tracking_device">TRACKING DEVICE</span>
                  <ol>
                    <li>
                      A tracking device must be installed in the Vehicle before delivery.
                    </li>
                    <li>
                      You will under no circumstances whatsoever be entitled to remove the tracking device or tamper with it. The removal of or any tampering with the tracking device will be considered a material breach of this Agreement and the Bank will be able to exercise its rights in terms of clause 9 above.
                    </li>
                    <li>
                      Should the tracking device be faulty or damaged, the Bank will have it repaired as soon as reasonably practicable at its own cost. You will be obliged to take the Vehicle to a designated repair centre on receiving notice from the Bank to do so. The distance travelled during the period of such failure will be determined by the odometer of the Vehicle.
                    </li>
                    <li>
                      You irrevocably consent to the tracking of the Vehicle by the Bank.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>ADDRESS FOR NOTICE AND SERVICE OF LEGAL DOCUMENTS</span>
                  <ol>
                    <li>
                      You and we choose our respective street addresses as set out above (“domicilium”) for all purposes of this Agreement. Each party may change its domicilium to another street address in the Republic of South Africa on written notice to the other.
                    </li>
                    <li>
                      Unless otherwise stated, notices must be given in writing, and notice will be deemed to have been received:
                      <ol>
                        <li>
                          seven Business Days after being posted, if it was sent by registered post, or
                        </li>
                        <li>
                          on the next Business Day if it was delivered by hand or
                        </li>
                        <li>
                          on transmission of a facsimile or other recognised electronic transmission to the party’s then fax number or electronic mail address.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Where the Post Office does not make street deliveries at your domicilium, you authorise us to send notices under this Agreement to your box number for postal deliveries from time to time.
                    </li>
                  </ol>
                </li>
                <li>
                  <span id="registration_licence">REGISTRATION, LICENCE FEES AND FINES</span>
                  <ol>
                    <li>
                      The Vehicle will be registered with us being the Title Holder and you being the Owner.
                    </li>
                    <li>
                      You can only accept delivery of the Vehicle after we have ensured that the Vehicle is registered and licensed.
                    </li>
                    <li>
                      You will be liable for the annual licence renewals and, if applicable, any re-registrations.
                    </li>
                    <li>
                      It is your duty to submit the Vehicle to an appropriate authority at your own cost to obtain a certificate of fitness as required from time to time in terms of prevailing road transportation legislation. You will be liable for all costs incurred in the issuing of any such certificate.
                    </li>
                    <li>
                      It is your duty to display all tokens, certificates and licences required from time to time in terms of the prevailing road transportation and/or other legislation.
                    </li>
                    <li>
                      You will be liable for all fines, penalties and toll fees arising directly or indirectly out of or in connection with the use, possession, driving or parking of the Vehicle.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>WARRANTIES, REPRESENTATIONS AND UNDERTAKINGS</span>
                  <ol>
                    <li>
                      You warrant and represent to us on the date on which you sign this Agreement and every day thereafter for the duration of this Agreement that:
                      <ol>
                        <li>
                          you have the full capacity to effect and carry out your obligations in terms of this Agreement;
                        </li>
                        <li>
                          the terms of this Agreement do not conflict with and do not constitute a breach of the terms of any other agreement or undertaking or act that is binding on you;
                        </li>
                        <li>
                          you and any person who provides Collateral have advised us if there are any requirements in your/their Constitutive Documents that have the effect of amending any alterable provisions of the Companies Act, particularly in respect of your borrowing powers and, if applicable, your/their power to provide Collateral, and that you/they have taken all necessary steps, fulfilled all formalities and obtained all approvals required by your/their Constitutive Documents as well as the Companies Act;
                        </li>
                        <li>
                          all the information that you provided to us in connection with the conclusion of this Agreement is in all aspects true, complete, current and accurate, and that you are not aware of any material facts or circumstances not disclosed to us that, if disclosed, would adversely affect our decision to conclude this Agreement;
                        </li>
                        <li>
                          you are not in default in respect of any of your material obligations in connection with this Agreement and that no default as specified in clause 9 above has occurred or is occurring;
                        </li>
                        <li>
                          you will comply at all times with legislation and other laws applicable to this Agreement and to your activities including but not limited to, where applicable:
                          <ol>
                            <li>
                              ensuring that the Vehicle is properly licensed and registered with the relevant authorities at all times for the duration of this Agreement;
                            </li>
                            <li>
                              ensuring that any person who operates or uses the Vehicle is in possession of a valid licence or registration certificate from the relevant authorities at all times for the duration of this Agreement;
                            </li>
                            <li>
                              all environmental laws and responsibilities; and
                            </li>
                            <li>
                              confirming that you have complied and will comply with all exchange control regulations, rulings and requirements applicable to this Agreement from time to time.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <li>
                      You must tell us immediately if you are placed under an administration order, become insolvent, or have any form of legal disability. If you apply for insolvency, any amount outstanding under this Agreement will immediately become due, owing and payable to us.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>DATA PROTECTION</span>
                  <ol>
                    <li>
                      You consent to us collecting your Personal Information from you and where lawful and reasonable, from public sources for credit, fraud and compliance purposes, as well as the purposes set out below.
                    </li>
                    <li>
                      If you give us Personal Information about or on behalf of another person (including, but not limited to, account signatories, shareholders, principal executive officers, trustees and beneficiaries), you confirm that you are authorised to: (a) give us the Personal Information; (b) consent on their behalf to the Processing of their Personal Information, specifically any cross-border transfer of Personal Information into and outside the country where the products or services are provided; and (c) receive any privacy notices on their behalf.
                    </li>
                    <li>
                      You consent to us Processing your Personal Information:
                      <ol>
                        <li>
                          to provide products and services to you in terms of this agreement and any other products and services for which you may apply;
                        </li>
                        <li>
                          to carry out statistical and other analyses to identify potential markets and trends, evaluate and improve our business (this includes improving existing and developing new products and services);
                        </li>
                        <li>
                          in countries outside the country where the products or services are provided. These countries may not have the same data protection laws as the country where the products or services are provided. Where we can, we will ask the receiving party to agree to our privacy policies;
                        </li>
                        <li>
                          by sharing your Personal Information with our third-party service providers and insurers, locally and outside the country where the products or services are provided. We ask people who provide services to us to agree to our privacy policies if they need access to any Personal Information to carry out their services; and
                        </li>
                        <li>
                          within the Standard Bank Group.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You will find our Processing practices in the Standard Bank Group’s and our privacy statements. These statements are available on the Standard Bank Group’s websites or on request.
                    </li>
                    <li>
                      If you are unsure about your tax or legal position because your Personal Information is Processed in countries other than where you live, you should get independent advice.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>SANCTIONS</span>
                  <ol>
                    <li>
                      You must not:
                      <ol>
                        <li>
                          act in a way that benefits a Sanctioned Entity;
                        </li>
                        <li>
                          be involved in any Prohibited Activities; or
                        </li>
                        <li>
                          use any product or service provided by the Standard Bank Group for any Prohibited Activities.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You warrant (promise) that you are not:
                      <ol>
                        <li>
                          a Sanctioned Entity; or
                        </li>
                        <li>
                          being investigated for any activities relating to Sanctions.
                        </li>
                      </ol>
                    </li>
                    <li>
                      You must let us know immediately in writing if you are being investigated for any activities relating to Sanctions.
                    </li>
                    <li>
                      You indemnify the Standard Bank Group (excluding Liberty Holdings Limited and its subsidiaries) against (you are responsible for) any fines, penalties, losses, damages, costs, actions, proceedings, claims or demands (Losses) which the Standard Bank Group (excluding Liberty Holdings Limited and its subsidiaries) may suffer because:
                      <ol>
                        <li>
                          any funds are seized or withheld by any Sanctioning Body or any other third party (including by us); or
                        </li>
                        <li>
                          you breach this clause 18.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If we know or suspect that you are in breach of this clause 18 or you are about to become subject to Sanctions, we can immediately, in our sole discretion:
                      <ol>
                        <li>
                          close, restrict activity or suspend access to the Accounts and any other product or service we provide you; and/or
                        </li>
                        <li>
                          cancel this Agreement and/or any other relationship which we have with you.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We are not liable (responsible) to you for any Losses you suffer if we cancel these Terms or any other relationship which we have with you.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>INFORMATION SHARING</span>
                  <ol>
                    <li>
                      By entering into this Agreement, you acknowledge and agree that we may provide the following information to any registered credit bureaux:
                      <ol>
                        <li>
                          details provided by you in your application for credit and in this Agreement; and/or
                        </li>
                        <li>
                          details of the conduct on your Account; and/or
                        </li>
                        <li>
                          details of any adverse information. In respect of such adverse information, we will give you at least 20 (twenty) Business Days’ notice of our intention to provide the credit bureaux with this information; and/or
                        </li>
                        <li>
                          details of the transfer of our rights as a credit provider under this Agreement to another person; and/or
                        </li>
                        <li>
                          any other details as may be required by any applicable law.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Based on their records, the credit bureaux may provide a credit profile and/or a credit score on your creditworthiness to other credit providers.
                    </li>
                    <li>
                      You have the right to contact the credit bureaux to have your credit record with it disclosed and to request the correction of inaccurate information. The name(s) and contact details of the credit bureaux will be made available to you on request and shall also be made available on our website.
                    </li>
                    <li>
                      We may provide details to the South African Fraud Prevention Services (“SAFPS”) of any conduct on your Account that gives us reasonable cause to suspect that the Account is being used for improper purposes. The SAFPS may in turn make this information available to other members of the SAFPS if they carry out credit or other checks on your name.
                    </li>
                    <li>
                      You agree that we may provide any Collateral Provider with a copy of this Agreement, together with any amendments thereto and/or details of the conduct of your Account at their request. In the event of any proposed or actual change referred to in this clause 19.5 we reserve the right to cancel this Agreement.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>CESSION BY THE BANK</span>
                  <ol>
                    <li>
                      To the maximum extent permitted by law, we may, without your consent or notice to you, cede all or part of our rights and/or delegate all or any part of our obligations under this Agreement, either absolutely or as collateral to any person, even though that cession and/or delegation may result in a splitting of claims against you.
                    </li>
                    <li>
                      If this Agreement is sold or ceded, you acknowledge that the servicing and/or administration of this Agreement may, to the maximum extent permitted by law:
                      <ol>
                        <li>
                          be performed by us on behalf of the purchaser/cessionary; or
                        </li>
                        <li>
                          be contractually managed by a third party on behalf of the purchaser/ cessionary; or
                        </li>
                        <li>
                          be performed by the purchaser/cessionary itself.
                        </li>
                        <li>
                          These services and administrations can include the giving of notices, the realisation of any Collateral and the recovery of amounts due under this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      If this Agreement, or any part thereof, is sold, ceded and/or delegated to any person (“the purchaser/cessionary”), you acknowledge that:
                      <ol>
                        <li>
                          we may elect not to cede or sell our rights under any Collateral given directly or indirectly in respect of your obligations under this Agreement (“relevant Collateral”) to the purchaser/cessionary;
                        </li>
                        <li>
                          notwithstanding the sale, cession and/or delegation of this Agreement, or any part thereof, to the purchaser/cessionary, the relevant Collateral, or any part thereof as the case may be, will continue to cover your indebtedness to us, our successors in title or assigns under the relevant Collateral, on the terms set out in the relevant Collateral, or any part thereof as the case may be.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Unless the context indicates otherwise, any reference to us in this Agreement will be deemed to include our cessionary, successor in title or agent.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>STATEMENTS</span>
                  <ol>
                    <li>
                      We will provide you with a periodic Statement of your Account.
                    </li>
                    <li>
                      You may dispute (i.e. query) all or part of the Statement delivered to you by sending us written notice of your dispute.
                    </li>
                    <li>
                      You should contact our Customer Contact Centre if you do not receive a Statement or if you require any additional Statements. If you do not receive a Statement, this will not entitle you to refuse or fail to pay any amount that is due to us.
                    </li>
                    <li>
                      Subject to the provisions of clause 21.1 of this Agreement, a transfer from or a credit to your Account will be processed on the date on which the transaction is effected.
                    </li>
                    <li>
                      We may subsequently adjust debits or credits to your Account so as to accurately reflect both your and our legal obligations.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>TAX INVOICE</span><br>
                  The Parties agree that this Agreement complies with the requirements under section 20(7) or 21(5) of the VAT Act.
                </li>
                <li>
                  <span>GENERAL</span>
                  <ol>
                    <li>
                      This is the whole Agreement between the Parties and no variation or waiver of any right under this Agreement will be of any force or effect unless it is agreed in writing.
                    </li>
                    <li>
                      No indulgence we may give you will affect any of our rights against you.
                    </li>
                    <li>
                      Should any of the clauses (or any portion of the clauses) in this Agreement be found to be invalid, illegal or unenforceable, this will not affect the remaining clauses in this Agreement, which will continue with full force and effect.
                    </li>
                    <li>
                      South African law will govern this Agreement. You consent to the Magistrate’s Court having jurisdiction in respect of any legal steps taken against you, even if the amount we claim exceeds the jurisdiction of the Magistrate’s Court. We may, however, approach a higher court if we wish to do so, in which case we may recover such costs as are awarded in the higher court.
                    </li>
                    <li>
                      A certificate signed by any of our managers (whose position as such need not be proved) as to any amounts owing by you in terms hereof will be prima facie proof (that is, sufficient proof unless the contrary is proved) for all purposes of your indebtedness to us. A certificate signed by our auditors (whose appointment need not be proved) as to the residual value of the vehicle and/or as to the increased costs under this Agreement from time to time will be conclusive proof of the contents thereof.
                    </li>
                    <li>
                      Any agreed changes to this Agreement will be made in writing, or if the changes are recorded telephonically, we will provide you with written confirmation of the change. We will deliver to you a document reflecting the agreed amendment of the Agreement no later than 20 (twenty) Business Days after the date of such amendment. Unless specifically stated and agreed in writing, no amendment to this Agreement will create a new agreement. The provisions of the Electronic Communications and Transactions Act 25 of 2002 do not apply to this clause.
                    </li>
                    <li>
                      Any version of this Agreement translated from this English version will be provided to you, on request, for information purposes only. While the translated version of the Agreement describes the rights and obligations contained in this Agreement, please note that this English version of the Agreement signed by you constitutes the legally binding Agreement between us. Therefore, any enforcement procedures in respect of the Agreement will be based on this English version.
                    </li>
                    <li>
                      You have the right to resolve any complaint by referring the matter to a dispute resolution agent, the consumer court or the ombud with jurisdiction and/or, in respect of any alleged contravention of the CPA, to file a complaint with the National Consumer Commission and/or make an application to the National Consumer Tribunal for an order resolving a dispute over information held by a credit bureau and/or an order compelling the delivery of a Statement and/or review of a Statement and/or permission to bring a complaint directly before the tribunal and/or an order allowing late filing.
                    </li>
                    <li>
                      The contact details of the Credit Bureau, the National Credit Regulator, the National Consumer Commission, the Tribunal and the Banking Ombud are available at your request and can also be found on our website.
                    </li>
                    <li>
                      To the extent that this Agreement, or the Goods or services that are the subject thereof, are governed by or subject to the CPA, no provision thereof is intended to contravene the applicable provisions of the CPA. All provisions of the Agreement will be deemed to be adequate to the extent required to ensure compliance with the applicable provisions of the CPA and this Agreement must be interpreted and applied accordingly.
                      <br>
                      You should contact us if you need further explanation of anything related to or referred to in, this Agreement.  You may contact us at our Customer Contact Centre, email us at vafprivatebankinginland@standardbank.co.za
                    </li>
                    <li>
                      or speak to a consultant at any Standard Bank branch or at the Vehicle and Asset Finance call centre at 0860000000.
                    </li>
                    <li>
                      The provisions of the National Credit Act No 24 of 2005 do not apply to the transaction recorded in this Agreement.
                    </li>
                    <li>
                      You must pay or reimburse us for VAT and any fiscal charges payable in connection with or arising from this Agreement and any costs, including attorney and own client costs, incurred by your breach of this Agreement.
                    </li>
                    <li>
                      Every term of this Agreement is separate and severable from the other. If a competent court finds any terms of this Agreement to be unenforceable, that term will be deleted and the remaining terms will not be affected.
                    </li>
                    <li>
                      If you cancel this Agreement you will pay us for the use of the Vehicle and any damage to the Vehicle while it is in your possession.
                    </li>
                    <li>
                      If anyone claims to be the owner of the Vehicle or to have greater rights to possess the Vehicle than you, you agree that any amounts already paid by you constitute payment for your use of the Vehicle up to date of repossession, whether or not you in fact used it.
                    </li>
                    <li>
                      A reference to any legislation includes any modified versions or derivatives or statutory re-enactments of that legislation that applies in South Africa.
                    </li>
                    <li>
                      This Agreement is in the official language chosen by you.
                    </li>
                  </ol>
                </li>
                <li>
                  <span>INSURANCE</span>
                  <ol>
                    <li>
                      The Vehicle is insured with Standard Bank Insurance Limited and the Bank’s interest must be noted on the insurance policy. The monthly insurance premium due is included in the rental payable by you.
                    </li>
                    <li>
                      You confirm that a copy of the insurance policy was provided to you on signature of this Agreement.
                    </li>
                    <li>
                      You must comply with all the terms and conditions of the insurance policy.
                    </li>
                    <li>
                      Subject to your rights under the CPA, if the Vehicle is:
                      <ol>
                        <li>
                          damaged, destroyed or lost, you must immediately notify us in writing and if required by us you must do everything necessary to ensure payment to us of the proceeds under the insurance policy; and/or
                        </li>
                        <li>
                          damaged and if required by us, you must restore the Vehicle at your own cost according to the manufacturer’s specifications and continue to meet all your obligations on or before their due date; and/or
                        </li>
                        <li>
                          damaged beyond economic repair, you must continue to meet your obligations to us under this Agreement or we will be entitled to cancel this Agreement by following the provisions of clause 9.4 of this Agreement; and/or
                        </li>
                        <li>
                          lost or destroyed and, where applicable, not recovered by us for any reason whatsoever, the value of the Vehicle will be deemed to be nil and we will be entitled to claim from you all outstanding amounts under this Agreement.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We will not be required to provide you with a substitute Vehicle or to credit your Account for lack of use of the Vehicle whether under the circumstances referred to in clause 8.4 or on any other occasion when the Vehicle is not available for your use.
                    </li>
                    <li>
                      Should any claim be made or any proceedings be instituted against us, you or any other person as a direct or indirect consequence of the use, possession, maintenance or operation of the Vehicle or as a result of an insurable event, you will notify us of such proceeding or event immediately.
                    </li>
                    <li>
                      You will cooperate fully with us and render all assistance in the investigation and defence of any claim or proceedings arising from a situation referred to in clause 8.6.
                    </li>
                  </ol>
                </li>
                <br>
                <div class="table table-condensed">
                  <div class="Title">
                    <p>
                      <span id="employee_rental_scheme">CUSTOMER RENTAL SCHEME MOTOR INSURANCE COVER<br><br>The vehicles are comprehensively covered including roadside assistance and car-hire for the duration of the rental agreement.
                        The Insurance is underwritten by Standard Bank Insurance Limited. (VAF Fleet is the Insured not the renter of the vehicle)
                      </span>
                    </p>
                  </div>
                  <div class="Row">
                    <div class="Cell">
                      <p>Basis of cover:</p>
                    </div>
                    <div class="Cell">
                      <p>Motor Fleet Policy</p>
                      <p>Comprehensive cover – social and domestic use</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Basis of indemnity:</p>
                    </div>
                    <div class="Cell">
                      <div class="Cell">
                        <p>Own damage:</p>
                        <p>Third-party liability:</p>
                        <p>Accident towing:</p>
                        <p>Roadside assistance:</p>
                        <p>SASRIA:</p>
                        <p>Windscreen:</p>
                        <p>Loss of keys:</p>
                        <p>Fire extinguishing charges:&nbsp;&nbsp;</p>
                        <p>Wreckage removal:</p>
                        <p>Car audio equipment:</p>
                      </div>
                      <div class="Cell">
                        <p>Retail value</p>
                        <p>R 2.5 million</p>
                        <p>R 3,000</p>
                        <p>Included</p>
                        <p>Included</p>
                        <p>Included</p>
                        <p>R10,000</p>
                        <p>R10,000</p>
                        <p>R25,000</p>
                        <p>R5,000</p>
                      </div>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Car hire:</p>
                    </div>
                    <div class="Cell">
                      <p>Included – 30 days – nil time excess</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Tracking system:</p>
                    </div>
                    <div class="Cell">
                      <p>Republic of South Africa, Namibia, Botswana, Lesotho, Swaziland, Zimbabwe or Malawi</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Territorial scope:</p>
                    </div>
                    <div class="Cell">
                      <p>Included – 30 days – nil time excess</p>
                    </div>
                  </div>
                  <br>
                  <div class="Row">
                    <div class="Cell1">
                      <p><span>Excess/Deductible:</span></p>
                    </div>
                    <div class="Cell">
                      <p>&nbsp;</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>1st Claim:</p>
                    </div>
                    <div class="Cell">
                      <p>R3 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>2nd Claim:</p>
                    </div>
                    <div class="Cell">
                      <p>R5 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>3rd Claim:</p>
                    </div>
                    <div class="Cell">
                      <p>R10 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Driver under 30 years old:</p>
                    </div>
                    <div class="Cell">
                      <p>additional R2 000</p>
                    </div>
                  </div>
                  <br>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Single-vehicle event:</p>
                    </div>
                    <div class="Cell">
                      <p>R2 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Windscreen Replacement:</p>
                    </div>
                    <div class="Cell">
                      <p>R1 000</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Windscreen Repair:</p>
                    </div>
                    <div class="Cell">
                      <p>Nil</p>
                    </div>
                  </div>
                  <div class="Row">
                    <div class="Cell1">
                      <p>Locks &amp; Keys:</p>
                    </div>
                    <div class="Cell">
                      <p>additional R25</p>
                    </div>
                  </div>
                </div>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end VdBlock -->
    </div>
    <!-- end DetailsPage__Content -->
  </div>
  <!-- end Container -->
</template>

<script>
var ScrollTo = require("vue-scrollto");

export default {
  name:"TermsConditions",
  watch: {
    $route() {
      this.selectTab();
    }
  },
  mounted() {
    this.$nextTick(() => {
      $("ul.nav-tabs li a").click(function() {
        $("ul.nav-tabs li.active").removeClass("active");
        $(this)
          .parent("li")
          .addClass("active");
      });
    });

    this.selectTab();

    if(this.$route.params.section) {
      ScrollTo.scrollTo(this.$route.params.section)
    }
  },
  methods: {
    selectTab() {
      $("ul.nav-tabs li.active").removeClass("active");
      $(".tab-pane").removeClass("active");
      $(this.$route.hash).addClass("active")
      $("a[href='"+this.$route.hash+"']")
        .parent("li")
        .addClass("active");
    }
  }
};
</script>

<style scoped>
  /* ===== Faq ===== */
  /*ol {*/
  /*  list-style-type: none;*/
  /*  counter-reset: item;*/
  /*  margin: 0;*/
  /*  padding: 0; }*/

  li {
    display: table;
    counter-increment: item;
    margin-bottom: 0; }

  li:before {
    content: counters(".") ". ";
    display: table-cell;
    padding-right: 0.6em; }

  li li {
    margin: 0; }

  li li:before {
    content: counters(item, ".") " ";
    font-weight: 600; }

  .tab-content.faq_tabs > .active {
    border: 0px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 48px;
    background: none; }

  a.readmore {
    text-decoration: underline; }

  h3.headinng_sec {
    padding-top: 33px;
    font-size: 22px;
    color: #0A2240;
    font-weight: 600;
    line-height: 27px; }

  h3.headinng_sec_2 {
    padding-top: 33px;
    font-size: 22px;
    color: #0A2240;
    margin-bottom: 6px; }

  li.active.faq_li {
    padding: 12px;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #ffffff;
    background-color: #ffffff;
    margin-bottom: -1px;
    font-size: 16px; }

  li.faq_li {
    padding: 12px;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 0px;
    background-color: #F4F4F4;
    font-size: 16px; }

  .tab-content.faq_tabs > .active {
    border: 1px solid #E0E0E0;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 48px; }

  a.faq_link {
    color: #0A2240; }

  ul.nav.nav-tabs.faq {
    border-bottom: 0px; }

  p.text_sec {
    font-size: 14px;
    line-height: 17px; }

  .title_faq {
    font-size: 36px;
    font-weight: 100;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 48px;
    padding-bottom: 24px; }

  ol.terms_ol li {
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px; }

  ol.terms_ol span {
    font-size: 22px;
    font-weight: 600;
    color: #0A2240;
    padding-right: 12px; }

  @media (max-width: 426px) {
    li.active.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #ffffff;
      background-color: #ffffff;
      margin-bottom: -1px;
      font-size: 16px;
      width: 33.33%;
      text-align: center; }
    li.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 0px;
      background-color: #F4F4F4;
      font-size: 16px;
      width: 33.33%;
      text-align: center; } }

  @media (max-width: 376px) {
    ol.terms_ol ol  {
      font-size: 12px;
      font-weight: 300;
      color: #0A2240;
      line-height: 24px;
      margin-left:-20px;
      margin-bottom: 18px; }
    ol.terms_ol li {
      font-size: 12px;
      font-weight: 300;
      color: #0A2240;
      line-height: 24px;
      margin-bottom: 18px; }
    ol.terms_ol span {
      font-size: 12px;
      font-weight: 600;
      color: #0A2240;
      padding-right: 12px; }
    h3.headinng_sec {
      padding-top: 33px;
      font-size: 14px;
      color: #0A2240;
      line-height: 17px; }
    li.active.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 1px solid #ffffff;
      background-color: #ffffff;
      margin-bottom: -1px;
      font-size: 12px; }
    p.text_sec {
      font-size: 14px;
      line-height: 17px; }
    li.faq_li {
      padding: 12px;
      border-top: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
      border-right: 1px solid #E0E0E0;
      border-bottom: 0px;
      background-color: #F4F4F4;
      font-size: 12px; } }



  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }

  .Table
  {
    display: table;
    width: 100%;
  }
  .Title
  {
    display: table-caption;
    font-weight: bold;
    font-size: larger;
  }
  .Heading
  {
    display: table-row;
    font-weight: bold;
  }
  .Row
  {
    display: table-row;
  }
  .Cell
  {
    display: table-cell;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
  }
  .Cell1
  {
    display: table-cell;
    width: 30%;
    font-size: 22px;
    font-weight: 300;
    color: #0A2240;
    line-height: 34px;
    margin-bottom: 18px;
  }
</style>
